import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Image from 'next/image';
import Header from '@components/Header';
import Footer from '@components/Footer';
import BackgroundSvg from '../public/background.svg';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { IoArrowBack } from 'react-icons/io5';

const Layout: FC = ({ children }) => {
  const router = useRouter();

  const [left, setLeft] = useState(0);
  const [sizes, setSizes] = useState({
    width: 0,
    height: 0,
  });
  const [style, setStyle] = useState(
    'M512,0 Q400,100 512,250 T540,520 T500,850 T512,1024 L1024 1024 L1024 0 Z',
  );

  useEffect(() => {
    if (router.pathname === '/redeem') {
      setStyle('M0,0 Q0,100 0,250 T0,520 T0,850 T0,1024 L1024,1024 L1024,0 Z');
    } else if (router.pathname === '/create') {
      setStyle(
        'M1024,0 Q1024,100 1024,250 T1024,520 T1024,850 T1024,1024 L1024,1024 L1024,0 Z',
      );
    } else {
      setStyle(
        'M512,0 Q400,100 512,250 T540,520 T500,850 T512,1024 L1024 1024 L1024 0 Z',
      );
    }
  }, [router, setStyle]);

  const calculateSizes = useCallback(() => {
    if (!window) return undefined;

    setLeft(Math.min(0, (window.innerWidth - 1024) / 2));
    if (window.innerWidth > window.innerHeight) {
      setSizes({
        width: window.innerWidth,
        height: window.innerWidth,
      });

      return undefined;
    }

    setSizes({
      width: window.innerHeight,
      height: window.innerHeight,
    });
  }, [setSizes, setLeft]);

  useEffect(() => {
    calculateSizes();

    window.addEventListener('resize', calculateSizes, false);

    return () => {
      window.removeEventListener('resize', calculateSizes, false);
    };
  }, [calculateSizes]);

  return (
    <main className="w-100 h-100">
      <div
        style={{
          position: 'fixed',
          height: '100vh',
          width: '100vw',
          overflow: 'hidden',
          zIndex: -1,
        }}
      >
        <svg
          style={{
            position: 'relative',
            left: `${left}px`,
          }}
          width={sizes.width}
          height={sizes.height}
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="1024" height="1024" fill="#a78bfa" />
          <path style={{ transition: '400ms' }} d={style} fill="#fcd34d" />
        </svg>
        {/*<Image*/}
        {/*  src="/background.svg"*/}
        {/*  alt="Background"*/}
        {/*  layout="fill"*/}
        {/*  objectFit="cover"*/}
        {/*  quality={100}*/}
        {/*  style={{*/}

        {/*  }}*/}
        {/*/>*/}
      </div>
      <div className="absolute top-4 left-4">
        <Link href="/" passHref>
          <a className="flex justify-center items-center gap-1 text-white shadow-lg active:shadow-none transition-all duration-300 bg-gray-700 hover:bg-gray-800 active:bg-gray-900 font-medium rounded-lg text-lg px-5 py-2.5 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">
            CryptoGift
          </a>
        </Link>
      </div>
      <Header />
      {children}
      <Footer />
    </main>
  );
};

export default Layout;
